@import '../base/index.scss';

.homepage {
  &-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1em;

    h1 {
      font-size: 3.75em;
    }

    h2 {
      font-size: 0.85em;
      text-align: center;
    }

    &_background {
      position: absolute;
      z-index: -1;
      width: 65%;
      max-width: 20em;
    }
  }

  @media #{$bp-375} {
    h2 {
      font-size: 1em;
    }
  }

  @media #{$bp-480} {
    h1 {
      font-size: 5em;
    }

    h2 {
      font-size: 1.25em;
    }
  }
}
