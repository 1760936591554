@import '../base/index.scss';

.projects {
  section {
    padding: 1em;
  }

  .window__content {
    background-color: #fff8cc;
  }

  .project-content {
    h3 {
      margin-bottom: 0.5em;
    }
  }

  .project-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.25em;
    margin-bottom: 1.5em;

    &__ctas {
      a {
        margin-bottom: 0.25em;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    p.date {
      font-size: 14px;
    }

    img {
      border-radius: 8px 8px 0 0;
    }

    span {
      text-align: center;
    }
  }
}
