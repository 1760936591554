@import '../base/index.scss';

.contact {
  .message-display {
    height: 50%;
    background-color: white;
    margin-bottom: 1em;
    border: 1px solid black;
    padding: 0.5em;
    overflow: scroll;

    .message {
      &[isvisible='false'] {
        display: none;
      }

      span {
        font-family: $title-font;
        font-size: 11px;
        padding-right: 0.25em;
      }

      &:nth-of-type(odd) {
        span {
          color: #ff00b6;
        }
      }

      &:nth-of-type(even) {
        span {
          color: #ca48ff;
        }
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      border: 1px solid black;
      background-color: white;
      font-family: $body-font;

      &:focus {
        outline: none;
        border: 2px dashed #bef3be;
      }

      &[error='true'] {
        border-color: red;

        &::before {
          content: 'All fields required';
          display: block;
          font-size: 10px;
        }
      }
    }

    textarea {
      padding: 5px;
      height: 5em;
    }

    span {
      color: red;
      font-family: $body-font;
      font-size: 11px;
    }

    .message {
      width: 100%;
      height: 100%;
      pointer-events: all;
      padding: 0;
      height: 170px;
      max-height: 5em;
      max-width: 100%;

      &-box {
        height: 5em;
        background-color: white;
        pointer-events: none;
        display: flex;

        p {
          position: absolute;
          z-index: 2;
          padding: 4px 8px;
        }
      }

      &-sent {
        transform: translate(100%, 50%);
      }
    }

    .button {
      width: max-content;
      align-self: flex-end;
      padding: 8px 35px;
      background-color: white;
      border-radius: 40px;
      margin-top: 0.5em;
    }
  }

  &-content {
    background-color: #d5e0ff;
    min-height: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;

    div {
      display: flex;
      flex-direction: column;

      &:first-of-type {
        width: 35%;

        @media #{$bp-480} {
          width: 40%;
        }
      }

      &:last-of-type {
        width: 55%;
      }
    }
  }

  &-message {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
