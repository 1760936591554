@import '../base/index.scss';

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 80%;
    margin-bottom: 0.25em;
  }

  .ctas {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    margin-top: 0.25em;

    a {
      color: #dd009e;
      max-width: fit-content;
      padding: 8px 25px;
    }

    a:first-of-type {
      background-color: #e9d5f7;
      margin-right: 0.5em;
      box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff,
        inset -2px -2px grey, inset 2px 2px #dfdfdf;
    }

    a:last-of-type {
      background-color: #cbffcb;
      box-shadow: inset -1px -1px #00571a, inset 1px 1px #fff,
        inset -2px -2px grey, inset 2px 2px #dfdfdf;
    }
  }
}
