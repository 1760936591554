.work {
  .window__content {
    background-color: #f9e7e9;
  }

  &-detail {
    padding-bottom: 1em;

    article {
      margin: 1em 0;
      display: flex;
      flex-direction: column;

      img {
        max-width: 80%;
        align-self: center;
        margin-bottom: 0.5em;
      }
    }
  }

  video {
    max-width: 100%;
  }

  &-video {
    background-color: black;
    display: flex;
    justify-content: center;
  }
}
