@import '../base/index.scss';

.about {
  .window__content {
    background-color: #bef3be;
  }

  &-content {
    .about-details {
      padding: 0 0.5em;

      ul {
        list-style-type: circle;
        margin: 0 1em;
      }

      p.about-blurb {
        font-size: 16px;
        font-weight: 600;
      }

      @media #{$bp-768} {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;

        img {
          width: auto;
          height: 20em;
        }
        div {
          padding: 0 0.25em;
        }
      }
    }

    img {
      display: block;
      width: 100%;
      max-width: 25em;
      margin: 0 auto;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;

    a {
      font-family: $title-font;
    }
  }
}
