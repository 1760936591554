// fonts

@font-face {
  font-family: 'StickyPops';
  src: local('StickyPops'), url('../../fonts/StickyPops.otf') format('opentype');
}

@font-face {
  font-family: 'Pixel';
  src: local('Pixel'), url('../../fonts/Pixel.ttf') format('truetype');
}

@font-face {
  font-family: 'Pixel Condensed';
  src: local('PixelCondensed'),
    url('../../fonts/PixelCondensed.ttf') format('truetype');
}

$body-font: 'Arial';
$title-font: 'Pixel', 'sans serif';
$display-font: 'StickyPops';

// colors
$white: #fff9f2;
$black: #000;
$red: #e76f51;
$orange: #f4a261;
$mustard: #e9c46a;
$teal: #2a9d8f;
$denim: #264653;

// breakpoints
$bp-375: 'screen and (min-width: 375px)';
$bp-480: 'screen and (min-width: 480px)';
$bp-600: 'screen and (min-width: 600px)';
$bp-768: 'screen and (min-width: 768px)';
$bp-900: 'screen and (min-width: 900px)';
$bp-1024: 'screen and (min-width: 1024px)';
$bp-1360: 'screen and (min-width: 1360px)';
$bp-1440: 'screen and (min-width: 1440px)';

$max-480: 'screen and (max-width: 479px)';
$max-600: 'screen and (max-width: 599px)';
$max-768: 'screen and (max-width: 767px)';
$max-900: 'screen and (max-width: 900px)';
$max-1024: 'screen and (max-width: 1023px)';
$max-1440: 'screen and (max-width: 1439px)';

$only-600: 'screen and (min-width: 600px) and (max-width: 767px)';
$only-768: 'screen and (min-width: 768px) and (max-width: 1023px)';
$only-1024: 'screen and (min-width: 1024px) and (max-width: 1439px)';
