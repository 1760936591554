.clock {
  position: absolute;
  height: 10em;
  width: 10em;
  background-color: lavenderblush;
  bottom: 2.5em;
  right: 0.5em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &-face {
    &-outer {
      background-color: lightgray;
      height: 90%;
      width: 90%;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &::after,
      &::before,
      .marking {
        content: '';
        display: block;
        border-bottom: 2px solid black;
        position: absolute;
        width: 95%;
      }

      &::before {
        transform: rotate(90deg);
      }

      .marking {
        &-one {
          transform: rotate(60deg);
        }

        &-two {
          transform: rotate(120deg);
        }

        &-three {
          transform: rotate(30deg);
        }

        &-four {
          transform: rotate(150deg);
        }
      }
    }

    &-inner {
      background-color: lightgray;
      height: 90%;
      width: 90%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: baseline;
      z-index: 2;
    }
  }

  .hand {
    &-hour {
      height: 4em;
      border-left: 1px solid black;
    }

    &-min {
      height: 2em;
      border-left: 1px solid gray;
    }

    &-sec {
      height: 3em;
      border-left: 1px solid red;
    }
  }
}
