.main-nav {
  z-index: 2;
  background-color: lightgray;
  padding: 0.25em;
  box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey,
    inset 2px 2px #dfdfdf;
  position: fixed;
  margin-bottom: 0;
  margin-top: auto;
  bottom: 0;
  top: auto;
  width: 100%;

  .main-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    a {
      box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff,
        inset -2px -2px grey, inset 2px 2px #dfdfdf;
      padding: 4px;
      font-family: 'Pixelated MS Sans Serif', Arial;
      margin-left: 0.25em;
    }
  }
}
