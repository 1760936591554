body,
p {
  font-size: 14px;
}

a {
  text-decoration: none;
  font-family: $body-font;
  color: black;

  &:visited,
  &:active {
    color: $black;
  }

  &:hover {
    opacity: 0.5;
  }
}

p,
ul {
  line-height: 1.5;
  font-family: $body-font;
}

h1 {
  font-size: 2em;
  font-family: $display-font;
}

h2 {
  font-size: 1.75em;
  font-family: $title-font;
}

h3 {
  font-family: $title-font;
}

h4 {
  font-size: 12px;
  font-family: $title-font;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}
