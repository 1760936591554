@import '../base/index.scss';

.window {
  box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #dfdfdf,
    inset -2px -2px grey, inset 2px 2px #fff;
  padding: 2px;
  position: absolute;
  left: 0;
  right: 0;
  top: 3em;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  margin-top: 4.5em;

  &[fullscreen='true'] {
    width: 100%;
    height: 100%;
    max-width: initial;
    top: -2.75em;

    .window__content {
      height: 100%;
    }
  }

  &__bar {
    background: #deb6f6;
    background: linear-gradient(1deg, #deb6f6 0%, #8dcffc 100%);
    background: -moz-linear-gradient(1deg, #deb6f6 0%, #8dcffc 100%);
    background: -webkit-linear-gradient(1deg, #deb6f6 0%, #8dcffc 100%);
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff,
      inset -2px -2px grey, inset 2px 2px #dfdfdf;
    height: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5px;

    &-text p {
      font-family: $title-font;
    }

    &-icons {
      display: flex;
      flex-direction: row;
      padding: 3px;

      button,
      div {
        background-color: lightgray;
        width: 1.25em;
        margin-left: 3px;
        box-shadow: inset 1px 1px #fff, inset -1px -1px grey,
          inset 2px 2px #dfdfdf;
        background-repeat: no-repeat;

        &.min {
          background-image: url('../../images/icons/min.svg');
          background-position: 4px 13px;
          background-size: 11px 1.5px;
        }

        &.max {
          background-image: url('../../images/icons/max.svg');
          background-position: center;
        }

        &.close {
          background-image: url('../../images/icons/close.svg');
          background-position: center;
        }
      }
    }
  }

  &__options {
    padding-left: 5px;
    background: lightgray;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff,
      inset -2px -2px grey, inset 2px 2px #dfdfdf;

    p span {
      text-decoration: underline;
    }
  }

  &__content {
    height: 45vh;
    margin: 0 auto;
    overflow-y: scroll;
    border: 0.5px solid lightgray;

    > section {
      padding: 1em;
      padding-bottom: 0;
      min-height: max-content;
      height: calc(100% - 1em);
    }

    ::-webkit-scrollbar-thumb {
      background-color: #dfdfdf;
      box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff,
        inset -2px -2px grey, inset 2px 2px #dfdfdf;
    }

    ::-webkit-scrollbar-corner {
      background: #dfdfdf;
    }

    ::-webkit-scrollbar {
      width: 16px;
    }
  }

  @media #{$bp-768} {
    max-width: 575px;
  }
}
