.options-nav {
  display: flex;
  background-color: lightgray;
  width: 100%;
  height: 1.5em;
  position: fixed;
  box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey,
    inset 2px 2px #dfdfdf;
  padding: 2px 0;
  z-index: 99;

  > li {
    display: flex;
    align-items: center;
  }

  &_option {
    > li:first-of-type {
      padding-left: 1em;

      span {
        text-decoration: underline;
        pointer-events: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .submenu {
      position: absolute;
      background-color: lightgray;
      z-index: 3;
      width: max-content;
      box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #dfdfdf,
        inset -2px -2px grey, inset 2px 2px #fff;
      margin-top: 4px;
      margin-left: 0.75em;

      &[isvisible='false'] {
        display: none;
      }

      li {
        padding: 2px 4px;

        a {
          line-height: 20px;
        }

        &:hover {
          a,
          p {
            width: 100%;
            display: block;
            background-color: #9da0fa;
            color: white;
          }
        }
      }

      a {
        color: black;
      }
    }
  }
}
