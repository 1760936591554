@import './base/index.scss';

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #deb6f6;
  background: linear-gradient(270deg, #deb6f6 0%, #8dcffc 100%);
  background: -moz-linear-gradient(270deg, #deb6f6 0%, #8dcffc 100%);
  background: -webkit-linear-gradient(270deg, #deb6f6 0%, #8dcffc 100%);
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul,
p {
  margin: 0;
}

button {
  border: none;
  background: none;
  padding: initial;

  &[data-disabled='true'],
  &[disabled] {
    text-shadow: 1px 1px 0 #fff;
    color: gray;
  }
}
