.grid {
  $main: #9d6cf2;
  $glow: rgba(#6d68fe, 0.4);
  background: linear-gradient(
      transparent 65%,
      $glow 75%,
      $main 80%,
      $glow 85%,
      transparent 95%
    ),
    linear-gradient(
      90deg,
      transparent 65%,
      $glow 75%,
      $main 80%,
      $glow 85%,
      transparent 95%
    );
  background-size: 30px 30px;
  width: 101vw;
  height: 130vh;
  transform: rotateX(55deg);

  &-container {
    perspective: 700px;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}
